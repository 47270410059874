import { LitElement, html, css } from 'lit';
import {debounce} from '../shared-components/utilities/debounce.js';
import { repeat } from 'lit/directives/repeat.js';
import { MDuesPage } from './mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import '@material/mwc-circular-progress';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { KaleChip } from '../shared-components/chip.js';
import {dayjs} from '../shared-components/utilities/dates.js';

export const formatAffiliateWithState = (unit) => `${unit.state ? `${unit.state} ` : ''}${formatAffiliate(unit)}`;
export const formatAffiliateWithStateAndSubunit = (unit) => `${formatAffiliateWithState(unit)}${unit?.subunit && String(unit?.subunit).trim() !== '' ? ` / subunit ${unit?.subunit}` : ''}`
export const formatAffiliate = (unit) => [
['C', 'council'],
['L', 'local'],
['U', 'sublocal']
].filter(([_, p]) => unit[p]).map(([abb, field]) => `${abb}${unit[field]}`).join(' / ');

//export const shortdatefmt = (d) => d.toLocaleDateString()
export const shortdatefmt = (d) => dayjs(d).format('MM/DD/YY')

const status_styles = css``;

const mdues_chip_style = css`
:host {
}
  #menu_holder {
    position: absolute;
    overflow: visible;
    background-color: pink;
    z-index: 10000;

    top: 0;
    left: 0;
  }
  #text {
    position: relative;
  }
  #menu{
  }

`;

export class MDuesChip extends KaleForm {
  static styles = [mdues_chip_style]
  static get properties() {
    return {
      ...super.properties,
      editable: { type: Boolean },
      disabled: { type: Boolean },
      deletable: { type: Boolean },
      actions: { type: Array },
      label: { type: String },
      icon: { type: String },
      leadingIcon: { type: String },
      trailingIcon: { type: String },
      compact: { type: Boolean },
      tooltip: { type: String },
    }
  }

  constructor() {
    super();
    this.actions = [
      {
        name: 'delete',
        event: "delete-item",
        icon: 'clear'
      },
      {
        name: 'rename',
        event: "rename-item",
        icon: 'edit'
      },
    ];
  }

  deleteItem(item) {
    this.dispatchEvent(new CustomEvent('delete-item', { bubbles: true, composed: true, detail: null }));
  }
  renameItem(item) {
    console.warn("RENAME", item);
  }

  firstUpdated() {
  }

  render() {
    return html`
      ${this.editable ? html`
      <kale-chip @click=${e => this.showMenu()} ?disabled=${this.disabled}  title=${this.tooltip} label=${this.label} trailingIcon='edit'></kale-chip>
      <mwc-menu corner="BOTTOM_START" id="item_menu">${this.actions.map(k => this.renderMenuItem(k))}</mwc-menu>
      ` : this.deletable ? html`
      <kale-chip @trailing-click=${e => this.deleteItem()} ?disabled=${this.disabled} title=${this.tooltip} label=${this.label} trailingIcon='delete'></kale-chip>
      ` : html`
      <kale-chip .icon=${this.icon} @click=${e => this.showMenu()} ?disabled=${this.disabled} title=${this.tooltip}  label=${this.label} trailingIcon=${this.editable ? 'edit' : this.deletable ? 'delete' : ''}></kale-chip>
      `
      }
    `
  }

  showMenu() {
    if (!this.editable) return;
    const menu = this.renderRoot.getElementById('item_menu');
    menu.anchor = this;
    menu.show();
  }

  renderMenuItem(item) {
    const { name, icon, event, action } = item;
    if (event) {
      return html`<mwc-list-item  @request-selected=${e => this.dispatchEvent(new CustomEvent(event, { bubbles: true, composed: true, detail: null }))} graphic="icon">${icon ? html`<mwc-icon slot="graphic">${icon}</mwc-icon>` : ''}${name}</mwc-list-item>`
    } else if (action) {
      return html`<mwc-list-item  @request-selected=${action} graphic="icon">${icon ? html`<mwc-icon slot="graphic">${icon}</mwc-icon>` : ''}${name}</mwc-list-item>`

    }
  }
}

window.customElements.define('mdues-chip', MDuesChip);

const list_item_styles = css`
  :host {
    box-sizing: border-box;
    height: 100%;
  }
  mwc-icon-button {
    margin: 12px;
    box-sizing: border-box;
  }
  
  kale-card {
    --card-bg: white;
    --card-border: var(--paper-grey-700);
    color: var(--paper-grey-900);
  }

  .card-header {
    background-color: var(--item-primary-color, var(--paper-pink-100));
    color: white;
    height: fit-content;
    padding: 0px;
		min-height: 72px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .card-header > * {
    font-size: 80%;
    display: inline-block;
    margin-right: 20px;
  }
  .card-header > *:last-child {
    margin-right: 0;
  }

  .header-name {
    font-size: 120%;
    font-weight: 600;
    padding-left: 30px;
  }

  .header-detail {
    display: inline-block;
  }
  
  .header-extra {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    /*font-family: "Share Tech Mono";*/
  }

  .header-date {
    font-weight: 900;
    font-stretch: condensed;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

  }

  .mod_date {
    font-size: 12px;
  }
  .header-date > span {
    font-size: 70%;
    font-weight: 100;
    margin-right: 8px;
  }

  .header-detail > .unit {
    font-size: 80%;
    font-weight: 100;
    display: inline-block;
    padding: 4px 8px;
    margin-right: 8px;
    border-radius: 8px;
    border: 1px solid white
  }

  .alias {
    font-weight: 100;
    display: inline-block;
    padding: 4px 8px;
    margin-right: 8px;
    border-radius: 8px;
    background-color: var(--alias-color);
    color: white;
  }

  .card-body {
    background-color: white;
    color: var(--paper-grey-900);
  }
  .card-body > div {
    margin-bottom: 12px;
  }

  .info_item {
    display: inline-block;
    margin-right: 12px;
  }

  .info_labels > td {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 70%;
    margin-right: 8px;
  }

  .info_label {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 70%;
    margin-right: 8px;
  }

  .info_label.important {
    font-weight: 900;
    font-size: 100%;
    background-color: var(--paper-yellow-900);
    color: white;
    padding: 4px 8px;
    border-radius: 8px;
  }

  .info_info {
    font-weight: 900;
  }

  .header-actions {
    float: right;
  }

  .footer-info {
    margin-top: 18px;
    color: purple;
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .bottom-buttons {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .chip-list {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .chip-list > * {
    margin: 4px;
  }

  .no-items {
    font-weight: 100;
    opacity: 0.6;
    font-style: italic;
  }

  .divider {
    display: block;

    height: 0px;
    border-top-width: initial;
    border-right-width: initial;
    border-left-width: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    margin: 0px;
    border-style: none none solid;
    border-image: initial;
  }
  

`;
export class MDuesListItem extends KaleForm {
  static styles = [list_item_styles];
  static properties = { ...super.properties, table_view: { type: Boolean }, editing: { type: Boolean }, selectable: { type: Boolean }, expanded: { type: Boolean } }
  static mutation_class = null;
  static form_name = "MDuesListItem[Base]"

  dataUpdate(new_data) {
    this.item = new_data;
  }
  finalUpdate(new_data) {
    this.item = new_data;
    this.editing = false;
    this.expanded = false;
    this.requestListUpdate();
  }

  set item(i) {
    this._item = i;
    this.requestUpdate('item');
  }
  get item() { return this._item }

  requestListUpdate() {
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.item }))
  }

  get mutation_instance() {
    console.log(`build mutation for ${this.constructor.form_name}: ${this.constructor.mutation_class.name}.`)
    if (!this.constructor.mutation_class) return null;
    if (!this._mutation) this._mutation = new (this.constructor.mutation_class)(
      (item) => this.dataUpdate(item),
      { changeMap: null },
      (item) => this.finalUpdate(item),
      (err) => console.error(err) //FIXME: more error checking/alerts
    );
    return this._mutation;
  }

  get locked() {
    return window?.mdues_period?.locked
  }

  save() {
    if (this.locked) {
      console.warn("PERIOD LOCKED");
      return;
    }
    super.save();
  }

  async save_impl(data) {
    this.item.tempname = undefined;
    const mut = this.mutation_instance;
    //console.log(this.constructor.name, "GENERIC SAVE_IMPL", mut, data);
    if (!mut || !this.item) return;
    //console.log("SAVE IMPL ACTUAL", data, this.item);
    mut.save(data, this.item);
    this.saveComplete(Object.keys(data));
  }

  delete() {
    if (this.locked) {
      console.warn("PERIOD LOCKED");
      return;
    }
    const mut = this.mutation_instance;
    if (!mut || !this.item) return;
    mut.delete(this.item);
  }


  renderItemChipDesc(item) { return item.name }
  renderHeaderName(item) { return item.name }
  renderHeaderDetail(item) { return '' }

  get item_date() {
    return this?.item?.updated
  }

  renderHeaderDate(item) {
    let date = this.item_date ? new Date(this.item_date) : null;

    //return date ? html`<div class="header-date"><span>last modified:</span>${date.toLocaleDateString()} </div>` : '';

    return date ? html`<div class="header-date">
        <table>
            <tr class="info_labels"><td>Modified</td></tr>
            <tr><td></td></tr>
            <tr><td class="mod_date">${shortdatefmt(date)}</td></tr>
        </table>
    </div>` : '';
  }

  renderHeaderId(item) {
    return '';
  }
  renderItemBody(item) { return '' }
  //renderItemStatus({status}) { return html`<div class="info_item"><span class="info_label">status</span><span class="info_info">${status ? status.desc : 'unk'}</span></div>` }
  renderItemStatus({ status }) { return html`<div class="info_item"><status-control .status_code=${status.code}></status-control></div>` }
  renderItemEditableStatus({ status }) { return html`<div class="info_item"><span class="info_label">status</span><span class="info_info">${status ? status.desc : 'unk'}</span></div>` }

  renderItemRowBody(item) { return '' }

  //<div class="header-actions"><mwc-icon-button icon="edit"></mwc-icon-button></div>
  renderStatic(item) {
    //console.log('item ==========================================', typeof(item));
    return html`
            <div class="card-header-table" slot="full-bleed">
              <div class="card-header-table-body">
                <div class="card-header-row" @click=${e => this.expandItem()}>
                  <div class="card-header" >
                    <div class="card-header-cell" style="width:fit-content; min-width: 50%">
                      <div class="header-name">
                        ${this.renderHeaderName(item)}
                      </div>
                    </div>
                    <div class="card-header-cell" style="width:65%;">
                      <div class="header-detail">${this.renderHeaderDetail(item)}</div>
                    </div>
                    <div class="card-header-cell" style="width:5%;">
                      <div class="header-extra">
                        ${this.renderHeaderDate(item)}
                      </div>
                    </div>
                    <div class="card-header-cell" style="width:70px;">
                      <mwc-icon-button icon=${this.expanded ? "expand_less" : "expand_more"}></mwc-icon-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ${this.expanded ? html`
					  ${this.body_card ? html`
            <div class="card-body">
                ${this.renderItemBody(item)}
            </div>` : html`${this.renderItemBody(item)}`}
          ` : ''}
						`;
  }

	get body_card () { return true; }

  expandItem() {
    if (this.expanded)
      this.expanded = false;
    else
      this.expanded = true;
  }

  renderEditing(item) {
    return html`
    <div class="card-header" @click=${e => this.cancel(e, item)}>
      <div class="header-name">${item.tempname ? item.tempname : this.renderHeaderName(item)}</div>
        <div class="header-detail">${this.renderHeaderDetail(item)}</div>
    </div>
    ${this.renderEditForm(item)}
    `
  }
  renderEditForm(item) {
    return html`
      ${item.name ? html`
      <div>
        <kale-textfield id="name" label="name" field="name" .value=${item.name}></kale-textfield>
      </div>
      ` : ''}
    `;
  }

  chipClass(item) {
    return 'item-chip'
  }

  itemChipIcon(item) {
    return 'new'
  }
  renderItemChipTip(item) {
    return 'label goes here';
  }
  get itemChipEnabled() {
    return true;
  }
cancel(e, item) { 
    this.reset();
    this.editing = false;
    this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: true, detail: item }));
    if (item.is_new) {
      this.dispatchEvent(new CustomEvent('cancel-new', { bubbles: true, composed: true, detail: item }))
    }
  }

  render() {
    const { selectable, editing, expanded, item } = this;
    if (selectable) return html`
      <mdues-chip tooltip=${this.renderItemChipTip(item)} class=${this.chipClass(item)} .disabled=${!this.itemChipEnabled} icon=${this.itemChipIcon(item)} @click=${e => this.dispatchEvent(new CustomEvent('item-selection', { bubbles: true, composed: true, detail: item }))} label=${this.renderItemChipDesc(item)}></mdues-chip>
    `
    return html`
        <kale-card>
        ${this.editing ? this.renderEditing(item) : this.renderStatic(item)}

        ${this.expanded ? html` <div class="divider"> </div>` : ''}

        ${this.expanded ? html`
        <div class="footer-info">
          ${item && item.status && item.status.desc ? this.renderItemStatus(item) : ''}
          <div class="bottom-buttons"  title=${this.locked ? 'editing is locked for this period' : 'edit'}>
            ${this.editing ? html`
              ${this.extra_edit_actions && !item.is_new ? this.extra_edit_actions.map(a => html`
              <mwc-button icon=${a.icon} @click=${e => a.action(e, item)}>${a.name}</mwc-button>
              `) : ''}
              <mwc-button icon="cancel" @click=${e => this.cancel(e, item)}>${this.dirty || item.is_new ? "cancel" : "done"}</mwc-button>

              <mwc-button icon="save" ?raised=${this.dirty && this.valid} @click=${e => { this.save(); if (item.is_new) { this.dispatchEvent(new CustomEvent('saved-new', { bubbles: true, composed: true, detail: item })) } }} ?disabled=${!item.is_new && (!this.dirty || !this.valid)}> ${this.dirty && !this.valid ? "incomplete" : (this.dirty || item.is_new) ? (this.saving ? "saving..." : "save") : "saved"}</mwc-button>
            ` : html`
              ${this.extra_actions ? this.extra_actions.map(a => html`
              <mwc-button icon=${a.icon} @click=${e => a.action(e, item)}>${a.name}</mwc-button>
              `) : ''}
            <mwc-button icon=${this.locked ? 'lock' : 'edit'} ?disabled=${this.locked} @click=${e => this.editing = true}>edit</mwc-button>
            `}
          </div>
        </div>
        ` : ''}
    </kale-card>`;
  }
}

const result_list_styles = css`
  :host {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
  mwc-icon-button {
    margin: 12px;
    box-sizing: border-box;
  }

.column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0px;
    width: 100%;
  }

.column > * {
  padding-bottom: 8px;
}
.column.centered > * {
  width: calc(100vw - 48px);
  padding-bottom: 20px;
}

.column.centered {
  align-items: center;
}

.column.centered[in-progress] {
  opacity: 30%;
  filter: brightness(0.50);
}

.chip-list {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .chip-list > * {
    margin: 4px;
  }
  .chip-list[in-progress] {
    opacity: 30%;
    filter: brightness(0.50);
  }
  mwc-fab {
    position: fixed;
    right: 160px;
    bottom: 100px;
  }

 .appMenu mwc-icon-button {
    width: 80%;
    text-align: right;
    align-items: right;
  }
  
  .card-header-table{
        display: table;
        width: calc(100vw - 48px);
        
        border:1px;
        border-radius:8px;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        padding: 0px !important;

        margin-bottom: 20px;
  }
  
  .card-header-table-body {
        display: table-row-group;
  } 
  
  .card-header-row {
        display: table-row;
  }
  
  .card-header-cell {
        border: 0px solid #999999;
        display: table-cell;
        padding: 0px 0px;
        text-align: left;
  }
  
  .material-icons {
        position: relative;
        vertical-align: middle;
        top: 6px; 
  }  
  mwc-button.sort {
    --mdc-theme-primary: var(--top-bar-color);

  }

  .page-progress {
    position: fixed;
    top: 50vh;
    left: 50vw;
  }
  
  .selection-progress {
    position: absolute;
    top: 70%;
    left: 50%;
  }  
`;

export class MDuesResultList extends LitElement {
  static styles = [result_list_styles];
  static properties = {
    presentation: { type: String },
    table_view: { type: Boolean },
    results: { type: Array },
    selectionresults: { type: Array },
    filter: { type: Function },
    new_item: { type: Object },
    date_sort_desc: { type: Boolean },
    name_sort_asc: { type: Boolean },
    council_sort_asc: { type: Boolean },
    local_sort_asc: { type: Boolean },
    ignore: { type: Array },
    active_sorts: { type: Array },
    in_progress: { type: Boolean },
		period_year: { type: Number, hasChanged: (newVal, oldVal) => {
      //console.warn("!!!!!!!!! MDuesResultList instance:", this.prototype.constructor.name, `period_year: ${oldVal} => ${newVal} (changed=${newVal !== oldVal})`);
     return newVal !== oldVal;
    } },
  };

  updated(changedProperties) {
    if (changedProperties.has('period_year')) {
      console.warn("MDuesResultList updated period_year", this.period_year);
      if (this.period_year) this.doSearch();
    }
  }

  constructor() {
    super();
		this._instance = Math.random();
		//console.log("new MDuesResultList", this.constructor.name, this._instance);
    this.presentation = 'page';
    this.results = [];
    this.selectionresults = [];
    this.ignore = [];
    this.seq = 0;
    this.active_sorts = [];

		this.prior_params = null;
		this.prior_results = null;
  }
	firstUpdated() {
		//console.warn("RESULT LIST firstUpdated", this.constructor.name, this._instance);
	}

  get mdues_period_required() { return true }

  // set mdues_period(p) {
		// if (!p || typeof(p) !== 'number') {
		// 	console.warn(this._instance, "RESULT LIST set mdues_period(): INVALID PERIOD", p, typeof(p));
		// 	return;
		// }
		// if (this._period === p) {
		// 	return;
		// }
  //   this._period = p;
		// console.warn(this._instance, "RESULT LIST set mdues_period(): ", this.constructor.name, "PERIOD =>", this._period);
  //   this.doSearch();
  // }
  // get mdues_period() { return this._period }

  deduplicateResults(res) {
    let ids = new Set();
    let out = [];
    res.forEach(r => {
      if (!ids.has(r.id)) {
        ids.add(r.id);
        out.push(r);
      }
    });
    if (out.length !== res.length) console.warn(`DEDUPLICATED ${res.length - out.length}`);
    return out;
  }

  push(item) {
    this.results = [...this.results, item];
    this.requestUpdate();
  }

  handleSearchResults(seq, res, params) {
    if (seq >= this.seq) {
      res = this.deduplicateResults(res);

      let ignore = new Set(this.ignore.map(i => i.id));

      res = res.filter(r => !ignore.has(r.id));

      if (this.filter) {
        res = res.filter(r => this.filter(r));
      }

      this.results = [...res];


      this.requestUpdate();
      this.dispatchEvent(new CustomEvent('detail', { bubbles: true, composed: true, detail: null }));

      this.in_progress = false;

      return true;
    }
    return false;
  }

  formatSort() {
    const dirmap = { 'asc': 'asc_nulls_last', 'desc': 'desc_nulls_last' }
    let x = this.active_sorts.map(({ name, direction }) => Object.fromEntries([[name, dirmap[direction]]]));
    return x;
  }

  searchArgs() {
    return { ...this.search, period: this.period_year, order: this.formatSort() };
  }

  force_refresh() {
    console.log("FORCE REFRESHING LIST");
    this.search_results_dirty = true;
    this.doSearch();
  }
  refresh() {
    console.log("REFRESHING LIST");
    this.doSearch();
  }

  get search_class() {
    return this.constructor.search_class;
  }

  get order_by() {
    return [];
  }


  doSearch = debounce(() => this.doSearchActual(), this._debounce_time ?? 500, false);

  doSearchActual() {
    //this._period = 2024;
		//console.log(this.constructor.name, "DO SEARCH PERIOD =", this.period_year, this.mdues_period_required, this);
    if (this.mdues_period_required && !this.period_year) {
			// console.warn("Period required for search", this.constructor.name, this.mdues_period_required, this.mdues_period);
			console.warn("RESULT LIST: NO PERIOD TO SEARCH", this.constructor.name, this.searchArgs());
			return;
    }
		if (this.in_progress){ 
			console.warn("RESULT LIST: Search already in progress", this.constructor.name);
			return;
		}
    this.in_progress = true;

		const sa = this.searchArgs();
    const seq = ++this.seq;
		const params = JSON.stringify({ ...sa, period: this.period_year});
		if (!this.search_results_dirty && this.prior_params == params && this.prior_results) {
			//console.warn("RESULT LIST: PRIOR RESULTS", this.search_results_dirty, this.constructor.name, this.prior_results);
			this.handleSearchResults(seq, this.prior_results);
			this.in_progress = false;
			return;
		}

    console.log("RESULT LIST.doSearch:", this.constructor.name, sa, seq, this.search_class?.prototype?.constructor?.name);


    const timeout = setTimeout(() => {
      console.warn("RESULT LIST: SEARCH TIMEOUT", this.constructor.name, this.searchArgs());
      this.in_progress = false;
      this.doSearch();
    }, 10000);
    const results = this.search_class ? new (this.search_class)(res => {
      clearTimeout(timeout);
			this.prior_results = res;
			this.prior_params = params;
      this.search_results_dirty = false;
			this.handleSearchResults(seq, res, params);
		},sa) : null;
		if (!results) this.in_progress = false;
  }


  set search(s) {
		//if (s.period) { s.period = parseInt(s.period); this.mdues_period = s.period;}
		// console.warn("RESULT LIST set search()", this.constructor.name, JSON.stringify(this._search), "==>", JSON.stringify(s));
		const search = { period: this.period_year, ...s};
    if (this._has_result && JSON.stringify(search) === JSON.stringify(this._search)) {
			console.warn("RESULT LIST set search(): DUPLICATE SEARCH", this.constructor.name, JSON.stringify(this._search));
			return;
		};
		// console.warn("RESULT LIST set search()", this.constructor.name, JSON.stringify(this._search), "==>", JSON.stringify(s));
    this._search = search;
    this.doSearch();
  }

  get search() {
    return this._search;
  }

  renderItem(result) {
    return html``;
  }
  renderSelectionItem(result) {
    return html``;
  }

  fabAdd() {
    this.results = [{ is_new: true, tempname: this.new_item_title }, ...this.results.filter(r => !r.is_new)];
    this.requestUpdate('results')
  }

  removeNew() {
    this.results = [...this.results.filter(r => !r.is_new)];
    this.requestUpdate('results')
  }


  render() {
    if (this.presentation === 'selection') {
      return html`
          ${this.in_progress ? html`
          <mwc-circular-progress class="selection-progress" indeterminate></mwc-circular-progress>
        ` : ''}
      <div class="chip-list" ?in-progress=${this.in_progress}>${this.results.map(result => this.renderSelectionItem(result))}</div>`
    }

    return html`      
        ${this.in_progress ? html`
          <mwc-circular-progress class="page-progress" indeterminate></mwc-circular-progress>
        
        ` : ''}
        ${this.renderSortHeaders()}
        <div class="column centered" ?in-progress=${this.in_progress} @cancel-new=${() => this.removeNew()} @saved-new=${() => this.removeNew()}>
            ${this.new_item ? this.renderItem(this.new_item, true) : ''}
            ${this.results.map(result => this.renderItem(result))}
        </div>
        ${this.fab_title && this.new_item_title ? html`
       ` : ''}
      `;
  }
  //${repeat(this.results, r => r.id, result => this.renderItem(result))}

  renderSortHeaders() {
    const active = new Map(this.active_sorts.map(s => [s.name, s]));

    return html` 
            <div class="card-header-table">
              <div class="card-header-table-body">
                <div class="card-header-row">
                    ${this.constructor?.valid_sorts?.map(s => html`
                      <div class="card-header-cell" style=${`width: ${s.width}%`}>
                        <mwc-button class="sort" trailingIcon icon=${(active.has(s.field)) ? ((active.get(s.field).direction === 'asc') ? 'keyboard_arrow_down' : 'keyboard_arrow_up') : ''} label=${s.display} @click=${e => this.toggleSortField(s.field)}></mwc-button>
                      </div>
                    `)}
                </div>
              </div>
            </div>  
        `;
  }

  toggleSortField(field) {
    const directions = ['asc', 'desc'];

    let existing = this.active_sorts.find(s => s.name === field)
    existing = existing || { name: field };

    existing.direction = directions[(directions.findIndex(direction => direction === (existing.direction)) + 1) % 3];

    //add to the front of the active sorts list, remove anything with no direction
    this.active_sorts = [existing, ...this.active_sorts.filter(s => s.name !== field)].filter(s => s.direction);

    this.doSearch();
  }

}


const unit_search_styles = css`
  :host {
    width: 100%;
    padding: 24px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
  .filter-row, .option-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    --mdc-theme-primary: var(--top-bar-color);
    --mdc-theme-text-primary-on-background: var(--top-bar-color);
    --mdc-theme-surface: var(--top-bar-color);
    position: relative;
  }
  .filter-row > * {
    margin-right: 1em;
  }

  .option-row {
    padding-bottom: 12px;
    padding-left: 24px;
  }

  .option {
    position: relative;
    top: -9.5px;
  }

  .search-chips {
    position: absolute;
    right: 12px;
    top: 12px;
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  kale-chip {
    --chip-background: var(--paper-grey-700);
    --chip-color: var(--paper-grey-100);
  }

  kale-chip[selected] {
   --chip-background: var(--search-chip-color, var(--paper-purple-800));
   --chip-color: var(--paper-grey-100);
    opacity: 1;
  }


  kale-chip.search-opts {
   --chip-background: rgba(255,255,255,0.1);
   --chip-color: var(--paper-grey-600);
   --kale-chip-border: 1px solid var(--paper-grey-600);
  }

  kale-chip#masters-only {
    --search-chip-color: var(--paper-purple-800);
  }
  kale-chip#no-data-only {
    --search-chip-color: var(--paper-teal-800);
  }

  kale-chip.search-opts[selected] {
   --chip-background: var(--search-chip-color, var(--paper-purple-800));
   --chip-color: var(--paper-grey-100);
  }
`;

export class MduesSearchBox extends LitElement {
  static styles = unit_search_styles
  static properties = { default: {type: String}, compact: { type: Boolean } }


  set default(d) {
    if (this._default) return;
    this._default = d;
    this.doDefault();
  }

  doDefault() {
    if (!this.renderRoot) return;
    const entry = this.renderRoot.querySelector('mwc-textfield');
    entry.value = this._default;
    this.buildSearchFromFilter(this._default);
  }


  firstUpdated() {
    if (this._default) {
      this.doDefault();
    }
  }

  set search(s) {
    const search = {...s };
		if (JSON.stringify(search) === JSON.stringify(this._search)) {
			//console.warn("SEARCHBOX set search(): no change in set search", this.constructor.name);
			return;
		};
    //console.warn("SEARCHBOX set search():", this._search, "=>", search);
		this._search = search;
    this.dispatchEvent(new CustomEvent('search', { bubbles: true, composed: true, detail: this._search }));
  }
  get search() { return this._search }

  clear() {
    const fields = this.renderRoot.querySelector('mwc-textfield');
    console.log(fields);
    this.buildSearchFromFilter(fields.value);
  }


  renderRowOptions() {
    return html``
  }

  buildSearchFromFilter(f) {
    //console.log("BUILD SEARCH FROM FILTER", f);
    f = String(f).toLowerCase();
    const special = /:|[lcnus]+\d+/;
    const short = /([lcnus]+)(\d+)/;
    const args = {
      'l': 'local',
      'local': 'local',
      'loc': 'local',
      'c': 'council',
      'cn': 'council',
      'council': 'council',
      'st': 'state',
      't': 'state',
      'state': 'state',
      'u': 'subunit',
      's': 'subunit',
      'su': 'subunit',
      'sl': 'subunit',
      'unit': 'subunit',
      'subunit': 'subunit',
    };

    let tokens = f.split(" ").filter(t => t !== '');
    //console.log("tokens", tokens);
    let filter = tokens.filter(t => !t.match(special)).join("%");
    //console.log("filter", filter);
    let terms = tokens.filter(t => t.match(special));
    let expanded = terms.map(t => {
      if (t.match(/:/)) {
        let toks = t.split(':');
        return { type: args[toks[0]], val: toks[1] };
      }
      else {
        let m = t.match(short);
        return { type: args[m[1]], val: m[2] };
      }
    });

    //console.log(`filt: "${filter}" terms: [${terms.map(t => `"${t}"`).join(', ')}]`)
    //console.log("exp:", JSON.stringify(expanded));
    let term_obj = {};
    expanded.forEach(e => term_obj[e.type] = e.val);
    //console.log("TERM OBJ", JSON.stringify(term_obj));
    this.search = { filter: filter && filter !== '' ? `%${filter}%` : null, ...term_obj };
    console.log("FINAL SEARCH", JSON.stringify(this.search));
  }

  static label = "search";
  static compact_label = "filter";
  static helper = "EX: 'springfield clerical', 'L123', 'C31 court', 'state:OH local:7', etc.";
  static icon = "search";

  render() {
    return html`
      ${this.compact ? html`
        <div class="filter-row">
          ${this?.renderRowOptions?.()}
          <mwc-textfield @input=${e => this.buildSearchFromFilter(e.target.value)} console.log(e.target.value) style="flex: 1 1" outlined label=${this.constructor.compact_label} helper=${this.constructor.helper} icon=${this.constructor.icon}></mwc-textfield>
        </div>
      ` : html`
        <div class="filter-row">
          <mwc-textfield @input=${e => this.buildSearchFromFilter(e.target.value)} style="flex: 1 1" outlined label=${this.constructor.label} helper=${this.constructor.helper} icon=${this.constructor.icon}></mwc-textfield>

          <div class="search-chips">
          ${this?.renderRowOptions?.()}
          </div>
        </div>
      `}
    `
  }
}

window.customElements.define('mdues-search', MduesSearchBox);


class NewItemDialog extends KaleForm {
  static properties = { ...super.properties, table_view: { type: Boolean }, item: { type: Object }, editing: { type: Boolean }, selectable: { type: Boolean }, expanded: { type: Boolean } };

  static get properties() {
    return {
      ...(super.properties),
      opened: { type: Boolean }
    };
  }

  dataUpdate(new_data) {
    this.item = new_data;
  }

  finalUpdate(new_data) {
    this.dispatchEvent(new CustomEvent('item-saved', { bubbles: true, composed: true, detail: new_data }));
  }

  save_impl(data) { }

}

window.customElements.define('newitem-dialog', NewItemDialog);
export { NewItemDialog }
