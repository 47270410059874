import { LitElement, html, css, nothing } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import '@material/mwc-circular-progress';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { SearchMasters, EditMasterMember, EditMaster, SearchLocals } from '../queries/queries.js';
import { MDuesResultList, formatAffiliate, MDuesListItem, NewItemDialog, formatAffiliateWithState, formatAffiliateWithStateAndSubunit } from '../components/result_list.js';

const local_item_styles = css`
  :host {
    --item-primary-color: var(--unit-color);
  }

  :host([not_allowed]) {
    opacity: 0.3;
    --unit-color: var(--paper-grey-900);
    --unit-secondary-color: var(--paper-grey-500);
    cursor: not-allowed;
  }
  /* :host([has_increases]) {
    --unit-secondary-color: var(--paper-pink-500);
  } */

  :host([not_allowed]) .item-chip {
    cursor: not-allowed;
  }

  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }
  .item-chip {
    --chip-background: var(--unit-secondary-color);
    --kale-chip-border: 1px dotted var(--unit-color);
    --chip-color: var(--unit-color);
  }

  .deprecated_unit {
    background-color: var(--paper-red-700);
    color: white;
  }

`;

export class LocalItem extends MDuesListItem {
  static styles = [...super.styles, local_item_styles];

  set item(i) {
    super.item = i;
    if (i.increases.length > 0) {
      this.setAttribute("has_increases", "");
      this.has_increases = true;
    } else {
      this.removeAttribute("has_increases")
    }
    if (i.agreements.some(a => a.agreement.year === window.mdues_period.year && a.agreement.is_master)) {
      this.setAttribute("in_master", "");
      this.in_master = true;
    } else {
      this.removeAttribute("in_master")
      this.in_master = false;
    }

    if (this.in_master || this.has_increases) {
      this.disabled = true;
      this.setAttribute("not_allowed", "");
    } else {
      this.disabled = false;
      this.removeAttribute("not_allowed");
    }

  }
  get item() { return super.item }
  renderItemChipTip(item) {
    return this.in_master ? "local already in a master agreement" : this.has_increases ? "an increase is already entered for this local" : "";
  }
  get itemChipEnabled() { return !this.in_master && !this.has_increases }
  renderItemChipDesc(item) { return `${item.name} (${formatAffiliate(item)})` }
  renderHeaderName({ name = '' } = {}) { return name }
  renderHeaderDetail(local) {
    if (!local) return '';
    return html`${local.deprecated ? html`<div class="local deprecated_unit">deleted</div>`: ''}<div class="local">${local.state ? `${local.state} ` : ''}${formatAffiliate(local)}</div> <div class="local">stat = ${local?.stat?.find(s => s.period_id === window?.mdues_period?.id)?.stat?.toLocaleString()}</div>`;
  }
  renderItemBody({ aliases = [] } = {}) { return html`<div class="aliases"></div>` }
}

window.customElements.define('local-item', LocalItem);
const local_list_styles = css`
`;

export class LocalList extends MDuesResultList {
  static styles = [...super.styles, local_list_styles];
  static search_class = SearchLocals
  static properties = { 
    ...super.properties,
    fab_title: { type: String },
    new_item_title: {type: String},
    mdues_period_required: {type: Boolean},
    active_sorts: {type: Array}
  }
  constructor() {
    super();
    this.fab_title = "New Local"
    this.new_item_title = "New Local"
    this.mdues_period_required = false
    this.active_sorts = [ {name: 'name', direction: 'asc'}, {name: 'council', direction: 'asc' }, {name: 'local', direction: 'asc' }, {name: 'updated', direction: 'desc' } ];
  }
  
  render() {
    console.log("RENDER LOCAL LIST", this.results);
    return super.render();
  }
  searchArgs() {
        //return { ...this.search, period: this.mdues_period, order: this.formatSort() };

        let args = { ...this.search, period: this.mdues_period, order: this.formatSort() };
        //console.warn(this.constructor.name, "SEARCHING WITH", JSON.stringify(args));
        return args;
  }
  static valid_sorts = [
        {field: 'name', display: 'Name', width: 25},
        {field: 'council', display: 'Council', width: 7},
        {field: 'local', display: 'Local', width: 56},
        {field: 'updated', display: 'Date', width: 12}
  ]
  renderItem(item, is_new) {
    return html`<local-item @update-list=${() => this.refresh()} .editing=${item.tempname !== undefined} .item=${item}></local-item>`;
  }
  
  renderSelectionItem(item) {
    return html`<local-item @update-list=${() => this.refresh()} selectable .item=${item}></local-item>`;
  }
  
  add_item_saved(item) {
      this.results = [item, ...this.results];
      this.requestUpdate("results");
  }  
}
window.customElements.define('local-list', LocalList);

const agreement_item_styles = css`
  :host { 
    --item-primary-color: var(--master-color);
  } 
  .local-chip {
    --chip-background: var(--unit-color);
    --chip-color: white;
  }

  mdues-search#local_filter {
    --mdc-notched-outline-leading-width: 28px;
    --mdc-notched-outline-leading-border-radius: 28px 0 0 28px;
    --mdc-notched-outline-trailing-border-radius: 0 28px 28px 0;
  }
  local-list {
    height: 500px;
    overflow: auto;
  }
  mwc-textfield { 
    --kale-textfield-width: 700px;
  }

  .master_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

	span.name {
		font-weight: 900;
	}
	span.aff {
		font-weight: 100;
		display: inline-block;
		border: 1px solid var(--paper-grey-300);
		border-radius: 5px;
		padding: 5px 10px;
		margin-right: 20px;
		white-space: nowrap;
	}
	span.state {
		font-weight: 900;
		font-size: 115%;
		display: inline-block;
		width: 2em;
		margin-right: 6px;
		text-align: left;
	}
	span.council {
		font-size: 96%;
		display: inline-block;
		width: 2em;
		text-align: left;
	}
  .master_header > mwc-icon {
    color: var(--paper-red-500);
    margin-left: 20px;
  }

`;
export class AgreementItem extends MDuesListItem {
  static styles = [...super.styles, agreement_item_styles];
  static properties = { ...super.properties, agreement: { type: Object }, local_filter: { type: Object } }
  
  static mutation_class = EditMaster
  async save_impl(data) {
    console.log("SAVE IMPl", this.period_year, data);
    data.year = this.item.year ?? window.mdues_period.year;
    data.updated = new Date().toISOString();
    return super.save_impl(data);
  }
   
   
  renderLocals(locals) {
    if (!locals || locals.length === 0) {
      return html`<div class="no-items">no locals</div>`
    }
    console.log("RENDER LOCALS", locals);
    return html`
    <div class="chip-list">
      ${locals.map((local) => html`
      <mdues-chip class="local-chip" tooltip=${`${formatAffiliateWithStateAndSubunit(local)}`} @delete-item=${() => this.deleteLocal(local.id)} label=${`${local.name} (${formatAffiliate(local)})`} ?deletable=${this.editing}></mdues-chip>
      `)}
    </div>
    `;
  }
  renderHeaderName({ master = '', state, council, updated } = {}) {
		const formatted_name = master
		.replace(/master/i, '')
		.replace(state, '')
		.replace(',', '')
		.replace(`Council ${council}`, '')
		.replace(/Council \d+/, '')
		.replace(/C\d+/, '')
		.replace(/ +/, ' ')
		.trim();
		return html`<div class="master_header"><span class="aff"><span class="state">${state}</span><span class="council">C${council}</span></span><span class="name">${formatted_name}</span></div>`
	}

  dissolveMaster(item) {
    this.delete()
  }

  extra_edit_actions = [
		/*
    {
      name: 'dissolve',
      icon: 'delete_sweep',
      action: (e, item) => this.dissolveMaster(item)
    }*/
  ]
  deleteLocal(id) {
    console.log("DELETE LOCAL", {localid: id, agreement_id: this.item.id});
    console.log("ITEM IS", this.item);
    // C93 L944
    const target_local = new EditMasterMember(
      a => { console.log("UPDATE TARGET LOCAL DELETE", a) }, // update
      { changeMap: null }, // initial
      a => {
        console.log("FINAL TARGET LOCAL DELETE", a);
        this.item = { ...this.item, locals: this.item.locals.filter(u => u.local.id !== id) };
        const searcher = this.renderRoot.getElementById('local-search-list');
        searcher.refresh();
      },
      (e, msgs) => { console.error(e) } //errors
    );

    target_local.delete({ localid: id, agreement_id: this.item.id });
  }
  addLocal(local) {
    console.log("ADD LOCAL", local, this.item.id);
    const filterbox = this.renderRoot.getElementById('local-filter-box');
    const resultlist = this.renderRoot.getElementById('local-search-list');

    const target_local = new EditMasterMember(
      a => { console.log("UPDATE TARGET LOCAL", a) }, // update
      { changeMap: null }, // initial
      async a => {// final
        console.log("FINAL TARGET LOCAL", a.local);
        filterbox.clear();
        resultlist.force_refresh();
        await this.requestUpdate();
        this.item = { ...this.item, locals: [...this.item.locals, { local: a.local }] };
        console.log("ITEM IS NOW", this.item);
      }, 
      (e, msgs) => { console.error(e) } //errors
    );
    target_local.save({ agreement_id: this.item.id, localid: local.id }, {});
  }
  
  renderEditForm(item) {
    return html`
      <div>
        <kale-textfield id="name" label="Agreement Name" fullwidth field="master" .value=${item.master}></kale-textfield>
      </div>
      <div class="divider"></div>
      <h4>Member Locals:</h4>
      ${this.renderItemBody(item)}
      ${!item.is_new ? html`
      <div>
        <kale-toggle id="report_individually" label="List individual locals on collection form" field="report_individually" .value=${item.report_individually ?? false}></kale-toggle>
      </div>
      <h5>Add local:</h5>
      <mdues-search id="local-filter-box" 
      compact
        .default=${`${this.item.state ? `state:${this.item.state} ` : ''}${this.item.council ? `C${this.item.council} ` : ''}`}
      @search=${({ detail }) => {
          console.log("LOCAL FILTER", detail);
          this.local_filter = detail;
        }}
      ></mdues-search>
     
      <local-list
        id="local-search-list"
        .mdues_period=${this.item.period_id}
        .filter=${result => !result.agreements.some(a => a.agreement.id === this.item.id)}
        .presentation=${'selection'}
        .search=${this.local_filter}
        @item-selection=${e => this.addLocal(e.detail)}></local-list>
      ` : html`<div class="note">Save first to add locals</div>`}
    `;
    
        ///.filter=${result => result.agreements.filter(({ agreement_info: { period_id } } = {period_id: undefined}) => period_id === this.item.period_id).length === 0}
  }

  renderItemBody({ locals = [] } = {}) { 
      return html`<div class="aliases">${this.renderLocals(locals.map(u => u.local))}</div>` 
  }
}

window.customElements.define('agreement-item', AgreementItem);

const agreement_list_styles = css`
 `;

export class AgreementList extends MDuesResultList {
  static styles = [super.styles, agreement_list_styles];
  static search_class = SearchMasters;
  
  fab_title = "New Master";
  new_item_title = "New Master Agreement";
  
  constructor() {
    super();
    this.active_sorts = [
      { name: 'updated', direction: 'desc' },
      ///{ name: 'master', direction: 'asc' }
    ];
  }
  
  static valid_sorts = [
        {field: 'name', display: 'Name', width: 88},
        {field: 'updated', display: 'Date', width: 12}
  ]
  
  renderItem(agreement) {
    return html`
      <agreement-item 
        @update-list=${() => this.refresh()} 
        .editing=${agreement.tempname !== undefined} 
        .item=${agreement}
      >
      </agreement-item>`;
  }

  add_item_saved(item) {
      this.results = [item, ...this.results];
      this.requestUpdate("results");
  }
}
window.customElements.define('agreement-list', AgreementList);

const agreements_page_styles = css`
      :host { 
        background-color: white;
        --top-bar-color: var(--master-color);
      }   
`;

class MasterAgreementsPage extends MDuesPage {
  static styles = [...super.styles, agreements_page_styles]
  static icon = "assignment"
  static default_title = "Master Agreements"

  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      detail: { type: Object },
      new_dialog_open: { type: Boolean }
    };
  }
  
  renderPage() {
		console.log("RENDER MASTER PAGE", this.period_year);
    return html`
      <div class="column">
        <mdues-search @search=${({ detail }) => this.search = detail}></mdues-search>
        <agreement-list class="refresh-list" .period_year=${this.period_year} .search=${this.search} @detail=${({ detail }) => this.detail = detail}></agreement-list>
      </div>
      
      ${this.new_dialog_open? html`
          <newmaster-dialog
            @item-saved=${e=>this.handle_item_saved(e.detail)} 
            @new-agreement-dialog-closed=${e=>{
              this.new_dialog_open=false; 
            }}
          >
          </newmaster-dialog>` : ''}
        `;
  }

  handle_item_saved(item) {
     let list = this.renderRoot.querySelector('agreement-list');
     list?.add_item_saved(item);
  }  
  
  addNewAgreement() {
      if (!this.new_dialog_open)
        this.new_dialog_open = true;
  }
  
  renderExtraItems() {
    const { periods, mdp } = this;
    
    return html`
      <mwc-button slot="actionItems" icon="add"  @click=${e => this.addNewAgreement()}>New Master</mwc-button>

      ${super.renderExtraItems()}
      `
  }
    
}

window.customElements.define('agreements-page', MasterAgreementsPage);
export { MasterAgreementsPage }

const new_master_style = css`
  mwc-dialog {
    --mdc-dialog-scrim-color: rgba(0, 0, 0, 0.6);
    --mdc-dialog-min-width: 500px;
  }      
 
  kale-textfield {
     --kale-textfield-width: 500px;
  }
`;



class NewMasterDialog extends NewItemDialog {
  static styles = [new_master_style]
  static properties = {
    ...super.properties,
    table_view: {type: Boolean},
    item: { type: Object },
    editing: { type: Boolean },
    selectable: { type: Boolean },
    expanded: { type: Boolean },
    agreement_name_valid: { type: Boolean }
  }
  static mutation_class = EditMaster;

  static get properties() {
    return {
      ...(super.properties),
      opened: {type: Boolean }
    };
  }
  
  dataUpdate(new_data) {
    this.item = new_data;
  }
  
  finalUpdate(new_data) {  
    this.dispatchEvent(new CustomEvent('item-saved', { bubbles: true, composed: true, detail: new_data }));
  }
  
  updateList() {
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.item }))
  }
  
  get mutation_instance() {
    if (!this.constructor.mutation_class) 
        return null;
    
    if (!this._mutation) 
        this._mutation = new (this.constructor.mutation_class)(
            (item) => this.dataUpdate(item),
            { changeMap: null },
            (item) => this.finalUpdate(item),
            (err) => console.error(err) //FIXME: more error checking/alerts
       );
    
    return this._mutation;
  }
  
  save_impl(data) {
    console.log("SAVE IMPhiddenL", this.period_year, data);
    data.period_id = this.period_year;
    
    const mut = this.mutation_instance;
    mut.save(data);  
    this.saveComplete(Object.keys(data));
  }  
  
   validate(input_value) {      
       input_value.length != 0 ? this.agreement_name_valid = true :  this.agreement_name_valid = false;
   }
  
  render()  {
    return  html` 
      <mwc-dialog id="newAgreementDialog" heading="New Master Agreement" open .title="New Master"  
      @closed=${e=>{
          this.dispatchEvent(new CustomEvent('new-agreement-dialog-closed', { bubbles: true, composed: true, detail: null })) 
        }}>
        <div>
        <kale-textfield id="agreementName" label="Agreement Name" field="master" .value="New master Agreement" required 
            @input=${e1 => {
              const path = e1.composedPath();
              const input = path[0];
              input.value.length > 0 ? this.agreement_name_valid = true :  this.agreement_name_valid = false;
            }}
        ></kale-textfield>
        </div>
        <div class="divider"></div>
        
        <mwc-button
            id="primary-action-button"
            slot="primaryAction"
            @click=${e => this.save()}
            ?disabled=${!this.agreement_name_valid}
            dialogAction="agreementsaved">
            Save
        </mwc-button>
      
        <mwc-button
            slot="secondaryAction"
            dialogAction="close">
            Cancel
        </mwc-button>
      </mwc-dialog>`
      ;
  }

}

window.customElements.define('newmaster-dialog', NewMasterDialog);
export { NewMasterDialog }
